<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          İstekler
        </b-card-title>
      </b-card-header>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        fixed
      >
        <template #cell(police_no)="data">
          <div v-if="data.item.sirket">
            <small class="text-warning">{{ data.item.sirket }}</small>
          </div>
          {{ data.item.police_no }}
          <div v-if="data.item.plaka">

            <small class="text-primary">{{ data.item.plaka }}</small>
          </div>
          <div v-if="data.item.renewal_user">
            <small class="text-info">{{ data.item.renewal_user }}</small>
          </div>
        </template>
        <template #cell(username)="data">
          {{ data.item.username }}
          <div v-if="data.item.created">
            <small class="text-warning">{{ moment(data.item.created).format('DD.MM.YYYY') }}</small>
          </div>
        </template>
        <template #cell(status)="data">
          <div v-if="data.item.status === '1'">
            <p>
              <feather-icon icon="ClockIcon" />
              Beklemede
            </p>
          </div>
          <div v-if="data.item.status === '2'">
            <p class="text-success">
              <feather-icon icon="CheckCircleIcon" />
              Onaylandı
            </p>
          </div>
          <div v-if="data.item.status === '3'">
            <p class="text-danger">
              <feather-icon icon="XCircleIcon" />
              Reddedildi
            </p>
          </div>
        </template>
        <template #cell(control)="data">
          <b-dropdown
            text="İşlemler"
            variant="primary"
            size="sm"
            :disabled="data.item.status !== '1' && !data.item.interview_number"
            dropleft
          >
            <b-dropdown-item
              v-if="data.item.status === '1'"
              @click="acceptRequest(data.item.id,data.item.id_com_insurance_renewal)"
            >
              <feather-icon icon="CheckCircleIcon" />
              Onayla
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status === '1'"
              @click="declineRequest(data.item.id,data.item.id_com_insurance_renewal)"
            >
              <feather-icon icon="XCircleIcon" />
              Reddet
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.interview_number"
              :to="'/interviews/view/' + data.item.interview_number"
              target="_blank"
            >
              <feather-icon icon="MessageSquareIcon" />
              Görüşme Notu
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardTitle, BCardHeader, BTable, BCardFooter, BPagination, BDropdownItem, BDropdown,
} from 'bootstrap-vue'

export default {
  name: 'UpdateRequests',
  components: {
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardFooter,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      dataQuery: {
        limit: 10,
        start: 0,
      },
      fields: [
        {
          key: 'musteri',
          label: 'Müşteri',
        },
        {
          key: 'police_no',
          label: 'Poliçe No',
        },
        {
          key: 'username',
          label: 'Bildirimi Yapan',
        },
        {
          key: 'new_user',
          label: 'Yeniden Atanan',
        },
        {
          key: 'status',
          label: 'Durum',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['insuranceEditRequests/dataList']
    },
    dataCount() {
      return this.$store.getters['insuranceEditRequests/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('insuranceEditRequests/getDataList', this.dataQuery)
    },
    acceptRequest(id, index) {
      this.$swal({
        title: 'İsteği Onayla',
        text: 'İstek onaylandığında ilgili yenileme kaydı güncellenecek ve bu işlem geri alınamayacak. Devam etmek istiyor musunuz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('insuranceEditRequests/acceptRequest', { id_com_insurance_edit_requests: id, id_com_insurance_renewal: index })
            .then(res => {
              if (res.status) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız!',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    declineRequest(id, index) {
      this.$swal({
        title: 'İsteği reddetmek istiyor musunuz ?',
        text: 'İşlem geri alınamayacak ve bildirimi gönderen kullanıcı bilgilendirilecek.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('insuranceEditRequests/declineRequest', { id_com_insurance_edit_requests: id, id_com_insurance_renewal: index })
            .then(res => {
              if (res.status) {
                this.$swal({
                  icon: 'success',
                  title: 'İstek Reddedildi!',
                  text: 'İstek başarı ile reddedildi.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız!',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
